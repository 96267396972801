import React from "react";
import AlbumList from "../components/AlbumList";

const Albums = () => {
  return (
    <div>
      <h1>🎵 Альбомы</h1>
      <AlbumList />
    </div>
  );
};

export default Albums;
