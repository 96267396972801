import album1 from "../assets/album1.jpg";
import album2 from "../assets/album2.jpg";
import album3 from "../assets/album3.jpg";
import album4 from "../assets/album4.jpg";
import sbornik1 from "../assets/sbornik.jpg";
const albums = [

    {
        id: 1,
        name: "Альбомы",
        title: "Позвони мне без повода",
        cover: album1,
        albumId: 34458622 // ID альбома для iframe
    },
    {
        id: 2,
        name: "Альбомы",
        title: "История моей любви",
        cover: album2,
        albumId: 16710942 // ID альбома для iframe
    },
    {
        id: 3,
        name: "Альбомы",
        title: "Найди свою судьбу",
        cover: album3,
        albumId: 11128607 // ID альбома для iframe
    },
    {
        id: 4,
        name: "Альбомы",
        title: "Найди свою судьбу",
        cover: album4,
        albumId: 11128611 // ID альбома для iframe
    },
    {
        id: 10,
        name: "Сборники",
        title: "Найди свою судьбу",
        cover: sbornik1,
        albumId: 33527113 // ID альбома для iframe
    },
];

export default albums;
