import React from "react";
import "./Rider.css";
import soloImg from "../assets/solo01.jpg";
import vipImg from "../assets/solo2.jpg";
import rider1 from "../assets/t1.jpg";
import rider2 from "../assets/t2.jpg";
import rider3 from "../assets/t-2-1.png";

// Универсальный компонент для карточки райдера
const RiderCard = ({ title, description, price, image, files }) => {
  return (
    <div className="rider-card" style={{ backgroundImage: `url(${image})` }}>
      <div className="rider-overlay">
        <h3>{title}</h3>
        <div className="rider-details">
          <p>{description}</p>
          <hr />
          <p><strong>{price}</strong></p>
        </div>
        <div className="rider-links">
          {files.map((file, index) => (
            <a key={index} href={file.url} target="_blank" rel="noopener noreferrer" className="rider-file-link">
              📄 {file.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

// Данные о райдерах
const ridersData = [
  {
    title: "Сольное выступление",
    description: "Полный концерт с живым исполнением. Подробнее..",
    price: "от 50 000 т.р",
    image: soloImg,
    files: [
      { name: "Технический райдер", url: rider1 },
      { name: "Технический райдер-2", url: rider2 },
      { name: "Бытовой райдер", url: rider3 },
    ],
  },
  {
    title: "VIP ведущий",
    description: "Профессиональное ведение мероприятий. Подробнее..",
    price: "от 100 000 т.р",
    image: vipImg,
    files: [
      { name: "Технический райдер", url: rider1 },
      { name: "Технический райдер-2", url: rider2 },
      { name: "Бытовой райдер", url: rider3 },
    ],
  },
];

const Rider = () => {
  return (
    <div className="rider-container">
      <h3>Райдер</h3>
      <h4>
        <a href="tel:+79103107264">
          <strong>Звоните +7 (910) 310-72-64</strong>
        </a>
      </h4>
      <div className="rider-options">
        {ridersData.map((rider, index) => (
          <RiderCard key={index} {...rider} />
        ))}
      </div>
    </div>
  );
};

export default Rider;
