import React from "react";
import events from "../data/eventsData";
import style from "./EventList.css";

const EventList = () => {
  return (
    <div className="event-container">
      <h2 className="event-title">Ближайшие коцерты</h2>
      <div className="event-list">
        {events.map(event => (
          <div key={event.id} className="event-card">
            <div className="event-info">
              <h3>{event.title}</h3>
              <p>📅 <strong>Дата:</strong> {event.date}</p>
              <p>⏰<strong> Время:</strong> {event.time}</p>
              <p>📍<strong> Место:</strong> {event.place}</p>
              <p>🏟 <strong>Арена:</strong> {event.arena}</p>
            </div>
            <a href={event.link} target="_blank" rel="noopener noreferrer" className="buy-ticket">
              Купить билет
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventList;
