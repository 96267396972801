import React from "react";
import EventList from "../components/EventList";

const Events = () => {
  return (
    <div>
      <h1>🎭 Афиша</h1>
      <EventList />
    </div>
  );
};

export default Events;
