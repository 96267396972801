import React, { useEffect, useState } from "react";
import "./Home.css";
import AlbumList from "../components/AlbumList";
import EventList from "../components/EventList";
import VideoPlayer from "../components/VideoPlayer";
import artistImage from "../assets/Bg1.png";
import artistImage2 from "../assets/main5.png";
import Rider from "../components/Rider";
import Biography from "../components/Biography";
import Memorial from "../components/Memorial";
import PhotoGallery from "../components/PhotoGallery";

const Home = () => {
  const sections = ["главная", "биография", "память", "музыка", "концерты", "райдер", "видео", "галерея"];
  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    const observerOptions = { root: null, rootMargin: "0px", threshold: 0.6 };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(sections.indexOf(entry.target.id));
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    sections.forEach((id) => {
      const section = document.getElementById(id);
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, [sections]); // ✅ Добавили sections в зависимости

  return (
    <div className="home-container">
      {/* 🔘 Индикаторы справа */}
      <div className="scroll-indicators">
        {sections.map((id, index) => (
          <div key={index} className="indicator-wrapper">
            <a
              href={`#${id}`}
              className={`dot ${activeSection === index ? "active" : ""}`}
              aria-label={`Перейти к секции ${id}`}
              onClick={(e) => {
                e.preventDefault(); // Отключаем стандартный переход
                document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
              }}
            />
            <span className="indicator-label">{id}</span> {/* Название секции */}
          </div>
        ))}
      </div>



      {/* 🏠 Главный экран */}
      <div id="главная" className="hero-section">
        <div className="hero-content">
          <h1>Алексей Зардинов</h1>
          <h2>Заслуженный артист России</h2>
        </div>
        <div className="hero-image">
          <img src={artistImage2} alt="Алексей Зардинов" className="image-front" />
          <img src={artistImage} alt="Алексей Зардинов" className="image-back" />
        </div>
      </div>

      {/* 🎵 Биография */}
      <div id="биография" className="biography-section">

        <Biography />
      </div>

      {/* 🎵 память */}
      <div id="память" className="memorial-section">

        <Memorial />
      </div>


      {/* 🎵 Альбомы */}
      <div id="музыка" className="album-section">

        <AlbumList />
      </div>

      <div className="block2" >
        {/* 🎟 Афиша */}
        <div id="концерты" className="event-section">
          <h2>Афиша</h2>
          <EventList />
        </div>
        {/* 🎤 Райдер */}
        <div id="райдер" className="rider-section">
          <Rider />
        </div>
        {/* 🎬 Видео */}
        <div id="видео" className="video-section">
          <h2>Видео</h2>
          <VideoPlayer />
        </div>

        {/* 🎬 Галерея */}
        <div id="галерея" className=" photoGallery-section">
          <h2>Галерея</h2>
          <PhotoGallery />
        </div>

      </div>


    </div>
  );
};

export default Home;
