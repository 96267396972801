import React, { useState } from "react";
import styles from "./VideoPlayer.css";

const videos = [
  {
    id: "1b789fb83625bfd3391a99886d36b732",
    title: "Видео 1 — концерт С Днём Рождения! КЦ Салют 2023 г.",
  },
  {
    id: "a61be69e8dae96c658352a238ccc780c",
    title: "Видео 2 — Ты женщина мечты моей",
  },
  {
    id: "0cc46f1419ca0bbe22bf82f13e586123",
    title: "Видео 3 — Последний лист календаря",
  },
  {
    id: "172f865b4e760bdc0f78b680e8e241b9",
    title: "Видео 4 — Людмила",
  },
  {
    id: "dac3238f4828c33be8f9db3398ef180d",
    title: "Видео 5 — Алё, алё, Алёна",
  },
  {
    id: "725f561f7e9c374a0b56bc8aa1aa8f24",
    title: "Видео 6 — Юбилейный концерт в Vegas City Hall 2024 г.",
  },
  {
    id: "5b7efeca5084981128a51d42598f2c75",
    title: "Видео 7 -  Целуешь ты меня",
  },
  {
    id: "7c515927105dbf494004b9384cf64e06",
    title: "Видео 8 - Ты мой воздух (2019)",
  },
  {
    id: "ba8c6beaeffa125e317bbf5e02ae8337",
    title: "Видео 9 - Светлана",
  },
  {
    id: "24f8bb317f494c66fc69997f011205bc",
    title: "Видео 10 - Не спеши. Вечер памяти Арно Бабаджаняна г. Воронеж 2016",
  },
];

const VideoPlayer = () => {
  const [currentVideo, setCurrentVideo] = useState(videos[0].id);

  return (
    <div className="video-container">
      <div className="video-player">
        <iframe
          src={`https://rutube.ru/play/embed/${currentVideo}`}
          title="Rutube video player"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </div>

      <div className="video-thumbnails">
        <h3>Плейлист</h3>
        <div className="thumbnail-grid">
          {videos.map((video) => (
            <div
              key={video.id}
              className={`thumbnail ${currentVideo === video.id ? "active" : ""}`}
              onClick={() => setCurrentVideo(video.id)}
            >
              {video.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
