import React, { useState, useEffect } from "react";
import "./PhotoGallery.css";
import photo1 from "../assets/1.jpg";
import photo2 from "../assets/2.jpg";
import photo3 from "../assets/3.jpg";
import photo4 from "../assets/4.jpg";
import photo5 from "../assets/5.jpg";
import photo6 from "../assets/6.jpg";
import photo7 from "../assets/7.jpg";
import photo8 from "../assets/8.jpg";
import photo9 from "../assets/9.jpg";
import photo10 from "../assets/10.jpg";
import photo11 from "../assets/11.jpg";
import photo12 from "../assets/12.jpg";
import photo13 from "../assets/13.jpg";
import photo14 from "../assets/14.jpg";
import photo15 from "../assets/15.jpg";

const photos = [photo1, photo2, photo3, photo4, photo5, photo6, photo7, photo8, photo9, photo10, photo11, photo12, photo13, photo14, photo15];

const PhotoGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") closeModal();
      if (event.key === "ArrowRight") nextImage();
      if (event.key === "ArrowLeft") prevImage();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [currentIndex]);

  const openImage = (index) => {
    setSelectedImage(photos[index]);
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setCurrentIndex(null);
  };

  const nextImage = () => {
    if (currentIndex !== null) {
      const newIndex = (currentIndex + 1) % photos.length;
      setSelectedImage(photos[newIndex]);
      setCurrentIndex(newIndex);
    }
  };

  const prevImage = () => {
    if (currentIndex !== null) {
      const newIndex = (currentIndex - 1 + photos.length) % photos.length;
      setSelectedImage(photos[newIndex]);
      setCurrentIndex(newIndex);
    }
  };

  return (
    <div className="photo-gallery">
     
      <div className="gallery-grid">
        {photos.map((photo, index) => (
          <div key={index} className="gallery-item" onClick={() => openImage(index)}>
            <img src={photo} alt={`Фото ${index + 1}`} />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closeModal}>✖</button>
            <button className="prev-btn" onClick={prevImage}>⬅</button>
            <img src={selectedImage} alt="Просмотр фото" className="modal-image" />
            <button className="next-btn" onClick={nextImage}>➡</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
