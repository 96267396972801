import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Albums from "./pages/Albums";
import Events from "./pages/Events";
import Header from "./components/Header";


function App() {
  return (
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/albums" element={<Albums />} />
        <Route path="/events" element={<Events />} />
      </Routes>
     
    </Router>
  );
}

export default App;
