import React from "react";
import "./Memorial.css";
import bioPhoto from "../assets/i.webp"; // Фото

const Memorial = () => {
  return (
    <div className="memorial">
      <div className="memorial-header">
        <img src={bioPhoto} alt="Вячеслав Добрынин" className="bio-photo" />
        <div>
          <h2>Вячеслав Добрынин</h2>
          <p className="subtitle">Выдающийся композитор, певец и народный артист России</p>
        </div>
      </div>
      <div className="memorial-box">
        <h3>Творческий путь</h3>
      <p>
        Вячеслав Добрынин был выдающимся советским и российским композитором, певцом и народным артистом России.
        Его творческий путь начался в первой половине 1970-х годов, когда он написал музыку для песни
        <strong>«На Земле живёт любовь»</strong> на слова Леонида Дербенёва, исполненную ВИА «Весёлые ребята».
        В соавторстве с Дербенёвым он создал более **100 композиций**.
      </p>
      </div>
      
      <div className="memorial-box">
        <h3>Популярность и признание</h3>
      <p>
        Песни Добрынина исполняли многие известные артисты, включая <strong>Аллу Пугачёву, Михаила Боярского,
          Машу Распутину, Михаила Шуфутинского, Валентину Легкоступову</strong> и ВИА «Самоцветы».
        Его композиции становились **хитами**, объединяя поколения слушателей своими мелодиями и текстами.
      </p>
      </div>
      
      <div className="memorial-box"> 
        <h3>Награды и достижения</h3>
      <p>
        За свою карьеру Вячеслав Добрынин стал лауреатом множества конкурсов и фестивалей:
        **«Юрмала», «Братиславская лира», «Золотой Орфей», «Зелена Гура»**.
        Его вклад в отечественную культуру **невозможно переоценить**.
      </p>
      </div>
     
      <div className="memorial-box"> 
         <h3>Наследие</h3>
        <p>
          Вячеслав Добрынин оставил **богатое музыкальное наследие**.
          Его песни исполняли **звезды российской эстрады**, и они продолжают звучать.
          Группа <strong>«Доктор Шлягер»</strong> до сих пор радует поклонников его творчеством.
        </p>
      </div>

      <div className="memorial-box">
        <h3>🕯 Вечная память</h3>
        <p>
          Его музыка навсегда останется в сердцах слушателей, а его творчество продолжает вдохновлять
          новых исполнителей.
          Светлая память великому артисту.
        </p></div>

    </div>
  );
};

export default Memorial;
