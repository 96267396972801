import React, { useState } from "react";
import albums from "../data/albumsData";
import styles from "./AlbumList.css";

const AlbumList = () => {
  const categories = ["Альбомы", "Сборники"];
  const [activeCategory, setActiveCategory] = useState("Альбомы");

  // Фильтруем альбомы по категории
  const filteredAlbums = albums.filter(album => album.name === activeCategory);

  // Вертикальный слайдер: индекс текущего альбома
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleItems = 2; // Количество видимых альбомов

  // Анимация при прокрутке
  const [animationClass, setAnimationClass] = useState("");

  // Переключение категорий и сброс индекса
  const changeCategory = (category) => {
    setActiveCategory(category);
    setCurrentIndex(0);
  };
const handleAlbumClick = (albumId, index) => {
  if (index === 0) {
    nextSlide(); // Прокрутка вниз на 1 альбом
  } else if (index === visibleItems - 1) {
    prevSlide(); // Прокрутка вверх на 1 альбом
  } else {
    setSelectedAlbumId(albumId); // Просто меняем альбом без прокрутки
  }
};
  // Переключение альбомов (анимация)
  const nextSlide = () => {
    if (currentIndex < filteredAlbums.length - 1) {
      setAnimationClass("animate-up");
      setTimeout(() => {
        setCurrentIndex(prevIndex => prevIndex + 1);
        setSelectedAlbumId(filteredAlbums[currentIndex + 1]?.albumId); // Меняем iframe на следующий альбом
        setAnimationClass("");
      }, 300); // Длительность анимации
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setAnimationClass("animate-down");
      setTimeout(() => {
        setCurrentIndex(prevIndex => prevIndex - 1);
        setSelectedAlbumId(filteredAlbums[currentIndex - 1]?.albumId); // Меняем iframe на предыдущий альбом
        setAnimationClass("");
      }, 300);
    }
  };

  // Выбираем первый альбом по умолчанию
  const [selectedAlbumId, setSelectedAlbumId] = useState(filteredAlbums[0]?.albumId || null);

  return (
    <div className="yaBox">
      <div className="albums-container">
        <h2>Альбомы</h2>
        {/* Навигация между Альбомами и Сборниками */}
        <div className="category-buttons">
          {categories.map(category => (
            <button 
              key={category} 
              className={activeCategory === category ? "active" : ""}
              onClick={() => changeCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Кнопка вверх */}
        <button className="slider-btn up" onClick={prevSlide}>▲</button>

        {/* Вертикальный список альбомов с анимацией */}
        <div className="albums-wrapper">
          <div className={`albums-vertical ${animationClass}`}>
            {filteredAlbums.slice(currentIndex, currentIndex + visibleItems).map(album => (
              <div key={album.id} className="album-card" onClick={() => setSelectedAlbumId(album.albumId)}>
                <img src={album.cover} alt={album.title} />
                <h3>{album.title}</h3>
                 {/* Кнопка Play */}
                 <button className="play-button" onClick={() => setSelectedAlbumId(album.albumId)}>▶</button>
              </div>
            ))}
          </div>
        </div>

        {/* Кнопка вниз */}
        <button className="slider-btn down" onClick={nextSlide}>▼</button>
      </div>

      {/* Встраиваем iframe-плеер */}
      {selectedAlbumId && (
        <div className="yaBox2">
          
          <iframe
            frameBorder="0"
            allow="clipboard-write"
            className="iframe-player"
            src={`https://music.yandex.ru/iframe/album/${selectedAlbumId}`}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default AlbumList;
