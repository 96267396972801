import React from "react";
import "./Biography.css";

const Biography = () => {
  return (
    <div className="biography">
      <div className="bio">
        <h4>Биография</h4>
        <h2>Зардинов Алексей Геннадьевич</h2>
        <p className="subtitle">Заслуженный артист России, эстрадный певец, композитор и телеведущий</p>
      </div>
      <div className="bioBox">
        <h3>Ранние годы</h3>
        <p>
          Родился 3 января 1964 года в Ульяновске в семье, не связанной с шоу-бизнесом: мать работала поваром,
          а отец был капитаном речного флота. Однако оба родителя обладали музыкальными способностями,
          что, вероятно, повлияло на ранний интерес Алексея к музыке.
        </p>
        <p>
          В детстве Алексей самостоятельно научился играть на гитаре и пел в школьном ансамбле.
          В 1981 году, после победы в городском конкурсе эстрадной песни, был приглашён в группу «Айсберг»
          при городском Дворце пионеров.
        </p>
      </div>

      <div className="bioBox">
        <h3>Образование и армия</h3>
        <p>
          С 1981 по 1984 годы обучался в Ульяновском музыкально-педагогическом училище №2.
          В 1984 году переехал в Москву и поступил на факультет музыкального театра ГИТИСа.
        </p>
        <p>
          С 1985 по 1987 годы проходил службу в Советской армии.
          После службы Алексей продолжил музыкальную карьеру, став солистом-вокалистом Московской филармонии
          и актёром музыкального театра.
        </p>
      </div>


      <div className="bioBox">
        <h3>Карьера</h3>
        <p>
          В 2001 году он создал музыкальную группу «Алёшкина любовь», где является бессменным вокалистом.
          Его репертуар включает такие популярные песни, как <strong>«Разбитые мечты»</strong> и <strong>«Я не успел тебе сказать»</strong>.
        </p>
        <p>
          Алексей Зардинов принимал участие в телевизионных программах <em>«Утренняя звезда» (1997),
            «Сиреневый туман» (1998–1999), «Песня года» (1991–1993), «Два рояля» (1999)</em>.
        </p>
        <p>
          Он также был ведущим программ <em>«Музыкальная мозаика»</em> (ТВЦ, 1998) и
          <em>«Дамские штучки»</em> (Golden Palace, ТВЦ, 2000).
        </p>
      </div>


      <div className="bioBox">
        <h3>Награды и достижения</h3>
        <p>
          В 1993 году ему была вручена премия в номинации <strong>«Мистер газета Вечерняя Москва»</strong>.
        </p>
        <p>
          В 2008 году Алексей стал лауреатом телевизионного конкурса <strong>«Ветер Победы»</strong>,
          а в 2012 году был награждён медалью <strong>«За выдающийся вклад в отечественную культуру»</strong>.
        </p>
        <p>
          Его творчество продолжает радовать поклонников, а концерты собирают полные залы.
        </p>
      </div>


    </div>
  );
};

export default Biography;

